<template>
  <el-dialog
    title="退款原因"
    :visible.sync="openDialog"
    width="400px"
    class="dialog-vertical"
  >
    <el-form
      size="medium"
      :model="_data"
      :rules="rules"
      label-position="top"
      label-width="70px"
      ref="why"
      @submit.native.prevent
    >
      <el-form-item label="退款原因：" prop="why">
        <el-input
          type="textarea"
          :maxlength="50"
          show-word-limit
          :rows="4"
          v-model="why"
          placeholder="请填写退款原因"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button size="small" @click="cancel('why')">取消</el-button>
      <el-button
        size="small"
        type="primary"
        @click="submit('why')"
        :loading="saveLoading"
        >保存</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { orderRefund } from "../../api/order";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    // 订单id
    orderid: {
      type: String,
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      saveLoading: false,
      rules: {
        why: [{ required: true, message: "请填写原因", trigger: "blur" }],
      },
      why: "",
    };
  },
  methods: {
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          // this.$emit("addFeeConfig",this.form)
          const data = {};
          // 原因
          data.reason = this.why;
          // 订单id
          data.orderid = this.orderid;
          orderRefund(data)
            .then((res) => {
              this.$message.success(res.msg);
              this.updateList();
              this.openDialog = false;
              this.saveLoading = false;
              this.why = "";
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
      this.why = "";
      this.$refs[formName].resetFields();
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-vertical {
  ::v-deep.el-textarea__inner {
    width: 360px;
  }
  ::v-deep.el-form-item__label {
    padding-bottom: 0px;
  }
}
</style>
