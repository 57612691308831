<template>
  <div class="iframe-view" v-if="showIframe">
    <div class="close-btn-view">
      <div class="close-btn cursor-pointer" @click="showIframe = false">
        <div class="el-icon-circle-close"></div>
      </div>
    </div>
    <iframe class="main-content" :src="'data:application/pdf;base64,' + pdfBase64"></iframe>
  </div>
</template>

<script>
export default {
  model: {
    prop: "open",
    event: "closeIframe",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    pdfBase64: {
      type: String,
      default: "",
    },
  },
  computed: {
    showIframe: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeIframe", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.iframe-view {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  z-index: 10000;
  .close-btn-view {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    .close-btn {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background-color: #606266;
      display: flex;
      align-items: center;
      justify-content: center;
      .el-icon-circle-close {
        font-size: 24px;
        color: #ffffff;
      }
    }
  }

  .main-content {
    flex: 1;
  }
}
</style>