<template>
  <el-dialog
    title="上传发票"
    :visible.sync="openDialog"
    width="400px"
    class="dialog-vertical"
    @close="cancel('uploadData')"
  >
    <template v-if="uploadAgain || !rowData.invoice_file_name">
      <el-form
        size="medium"
        :model="uploadData"
        :rules="rules"
        label-position="right"
        label-width="80px"
        class="small-form"
        ref="uploadData"
        @submit.native.prevent
      >
        <el-form-item label="上传发票：" prop="file_name">
          <UploadButton
            ref="UploadFile"
            :text-name.sync="uploadData.file_name"
            :uploaded-text-name="uploadData.file_name"
            @uploaded="uploadData.file = $event"
            button-text="上传"
            buttonIcon=""
          />
          <p class="info">
            <i class="el-icon-info" />只能上传 jpg/png/pdf 文件
          </p>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="cancel('uploadData')">取消</el-button>
        <el-button
          size="small"
          type="primary"
          :loading="btnLoading"
          @click="submit('uploadData')"
        >
          {{ btnLoading ? "上传中" : "确定" }}</el-button
        >
      </span>
    </template>
    <template v-else>
      <div class="upload-success">
        <div class="el-icon-success"></div>
        <div>
          您已完成发票上传，<el-button
            class="check-invoice"
            type="text"
            @click="handleCheckInvoice"
            >查看发票
          </el-button>
        </div>
        <div>上次上传时间为：{{ rowData.invoice_upload_time }}</div>
        <el-button
          style="margin-top: 20px"
          type="primary"
          size="medium"
          @click="handleUploadAgain"
          >重新上传
        </el-button>
        <IframeView v-model="showIframe" :pdfBase64="pdfUrl" />
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { uploadInvoice, checkInvoice } from "../../api/order";
import UploadButton from "./UploadButton.vue";
import IframeView from "./IframeView";
export default {
  components: { UploadButton, IframeView },
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    rowData: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      uploadAgain: false,
      uploadData: {
        email: "",
        file: "",
        file_name: "",
      },
      rules: {
        file_name: [
          { required: true, message: "请上传发票", trigger: "change" },
        ],
      },
      pdfUrl: "",
      showIframe: false,
      btnLoading: false,
    };
  },
  methods: {
    // 查看发票
    handleCheckInvoice() {
      checkInvoice({ orderid: this.rowData.orderid })
        .then((res) => {
          if (this.rowData.invoice_file_name.indexOf(".pdf") !== -1) {
            this.pdfUrl = res.data;
            this.showIframe = true;
          } else {
            this.$previewImage(["data:image/png;base64," + res.data]);
          }
        })
        .catch((err) => {});
    },
    // 保存
    submit(formName) {
      if (this.rowData.status === 3) {
        // 订单已经退款了
        this.$message.warning("订单已退款，无法上传发票");
        return;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          const { file, orderid } = this.uploadData;
          const etxData = {
            orderid: this.rowData.orderid,
          };
          uploadInvoice(file, etxData)
            .then((res) => {
              this.$message.success(res.msg);
              this.btnLoading = false;
              this.cancel(formName);
              this.updateList();
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 重新上传
    handleUploadAgain() {
      if (this.rowData.status === 3) {
        // 订单已经退款了
        this.$message.warning("订单已退款，无法上传发票");
      } else {
        this.uploadAgain = true;
      }
      console.log(this.rowData);
    },
    // 取消
    cancel(formName) {
      this.uploadData = {
        email: "",
        file: "",
        file_name: "",
      };
      this.uploadAgain = false;
      this.openDialog = false;
    },
  },
  created() {},
  mounted() {},
  watch: {},
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  .el-icon-success {
    font-size: 48px;
    color: $success;
  }
}
</style>

