<template>
  <div class="list-page">
    <list-layout
      ref="listLayout"
      :filter-form="filterForm"
      :thead="thead"
      :tab-arr="tabArr"
      :on-fetch="getList"
      @stats="statsData"
    >
      <!-- 数据公告 -->
      <template #reminder>
        <Reminder
          v-loading="formLoading"
          :textArr="
            tenantVersion !== 'school' ? ReminderCommonText : ReminderSchoolText
          "
          title="数据公告"
        />
      </template>
      <template
        v-if="filterForm.tab !== 'checking'"
        #item_donation_status_text="{ row }"
      >
        <list-status-text :text="row.donation_status_text" />
      </template>
      <template v-slot:filter>
        <list-filter
          :filterForm="filterForm"
          :pidList="[]"
          :uploadFilter="ok"
        />
      </template>
      <template slot="item_info_avatar" slot-scope="{ row }">
        <list-image
          fit="cover"
          :width="40"
          border-radius="50%"
          :src="row.info_avatar"
        />
      </template>
      <template slot="item_invoice_file_name" slot-scope="{ row }">
        <list-status-text :text="row.invoice_file_name ? '已上传' : '未上传'" />
      </template>
      <template v-slot:operation="scope">
        <el-button size="small" @click="uploadInvoice(scope.row)" type="text">{{
          scope.row.invoice_file_name ? "重新上传" : "上传发票"
        }}</el-button>
        <!-- 已支付才可以 退款 -->
        <el-button
          size="small"
          v-if="scope.row.status === 0"
          @click="refundRow(scope.row)"
          type="text"
          >退款</el-button
        >
      </template>
    </list-layout>
    <!-- 上传发票 -->
    <UploadInvoice
      v-model="isShowUploadInvoice"
      :rowData="current_row"
      :updateList="refreshList"
    ></UploadInvoice>
    <ReasonDialog
      v-model="openReasonDialog"
      :orderid="currentOrderId"
      :updateList="refreshList"
    ></ReasonDialog>
  </div>
</template>

<script>
import UploadInvoice from "../../components/order/UploadInvoice";
import Reminder from "@/modules/common/components/Reminder";
import ListImage from "@/base/components/List/ListImage";
import ListStatusText from "@/base/components/List/ListStatusText";
import ListLayout from "@/base/layout/ListLayout";
import listFilter from "../../components/order/ListFilter";
import ReasonDialog from "../../components/order/ReasonDialog";
import { OrderList } from "../../api/order";
export default {
  components: {
    ReasonDialog,
    listFilter,
    ListLayout,
    ListStatusText,
    ListImage,
    Reminder,
    UploadInvoice,
  },
  data() {
    return {
      tabArr: [
        { label: "个人捐款", name: "personal" },
        { label: "企业捐款", name: "company" },
      ],
      thead: {
        personal: [
          { label: "排名", type: "index", minWidth: 100 },
          { label: "头像", prop: "info_avatar", type: "slot", minWidth: 100 },
          {
            label: "用户名称",
            prop: "info_name",
            minWidth: 200,
          },
          {
            label: "会内职务",
            prop: "title_name",
            minWidth: 250,
          },
          {
            label: "捐款金额",
            prop: "order_price",
            minWidth: 120,
          },
          {
            label: "订单号",
            prop: "orderid",
            minWidth: 200,
          },
          {
            label: "订单状态",
            prop: "status_text",
            minWidth: 120,
          },
          {
            label: "商户号",
            prop: "merchant",
            minWidth: 200,
          },
          {
            label: "捐款时间",
            prop: "create_time",
            minWidth: 200,
          },
          {
            label: "发票状态",
            prop: "invoice_file_name",
            minWidth: 200,
            type: "slot",
          },
          { label: "操作", type: "operation", width: 150 },
        ],
        company: [
          { label: "排名", type: "index", minWidth: 100 },
          { label: "logo", prop: "info_avatar", type: "slot", minWidth: 100 },
          {
            label: "企业名称",
            prop: "info_name",
            minWidth: 120,
          },
          {
            label: "捐款金额",
            prop: "order_price",
            minWidth: 120,
          },
          {
            label: "订单号",
            prop: "orderid",
            minWidth: 200,
          },
          {
            label: "订单状态",
            prop: "status_text",
            minWidth: 120,
          },
          {
            label: "商户号",
            prop: "merchant",
            minWidth: 200,
          },
          {
            label: "捐款时间",
            prop: "create_time",
            minWidth: 200,
          },
          {
            label: "发票状态",
            prop: "invoice_file_name",
            minWidth: 200,
            type: "slot",
          },
          { label: "操作", type: "operation", width: 150 },
        ],
      },
      // 筛选对象
      filterForm: {
        name: "",
        status: -1,
        orderid: "", // 订单号
        positionIds: [],
        merchant: "", // 商户号
        class_id: "", // 班级id
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        tab: "personal", //当前筛选tab
        page_size: 15, //每页多少条数据
        export_ids: [],
      },
      stats: {},
      // 公共版的公告
      ReminderCommonText: [],
      // 校友版的公告
      ReminderSchoolText: [],
      formLoading: true,
      isShowUploadInvoice: false,
      current_row: {},
      // 当前操作的id
      currentOrderId: "",
      // 是否打开退款弹窗
      openReasonDialog: false,
    };
  },
  methods: {
    getList(data) {
      return OrderList(data);
    },
    // 筛选回调
    ok(e) {
      // console.log(this.$refs.listLayout.pageData.status)
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.$refs.listLayout.getList(this.filterForm);
    },
    // 刷新列表
    refreshList() {
      this.$refs.listLayout.getList();
    },
    uploadInvoice(row) {
      this.current_row = row;
      this.isShowUploadInvoice = true;
    },
    statsData(data) {
      this.stats = data.stats;
      // 捐款项目
      const projects = this.stats.projects;
      const personals = this.stats.personals;
      const classes = this.stats.classes;
      const terms = this.stats.terms;
      const companies = this.stats.companies;

      this.ReminderCommonText = [
        `捐款项目已筹总金额：￥${projects.total}; 捐款项目金额前 3 名: `,
        `个人捐赠总金额：¥${personals.total}；捐赠排行前 3 名：`,
        `企业捐赠总金额：¥${companies.total}；捐赠排行前 3 名：`,
      ];
      this.ReminderSchoolText = [
        `捐款项目已筹总金额：￥${projects.total}; 捐款项目金额前 3 名: `,
        `班级捐赠总金额：¥${classes.total}；捐赠排行前 3 名：`,
        `届别捐赠总金额：¥${terms.total}；捐赠排行前 3 名：`,
      ];
      for (let i = 0; i < projects.rank.length; i++) {
        if (projects.rank[i].name && i < 3) {
          this.ReminderCommonText[0] += `${i + 1}、${
            projects.rank[i].name
          }（已筹金额：¥${projects.rank[i].order_price}）`;
          this.ReminderSchoolText[0] += `${i + 1}、${
            projects.rank[i].name
          }（已筹金额：¥${projects.rank[i].order_price}）`;
        }
      }
      for (let i = 0; i < personals.rank.length; i++) {
        if (personals.rank[i].name && i < 3) {
          this.ReminderCommonText[1] += `${i + 1}、${
            personals.rank[i].name
          }（已筹金额：¥${personals.rank[i].order_price}）`;
        }
      }
      for (let i = 0; i < companies.rank.length; i++) {
        if (companies.rank[i].name && i < 3) {
          this.ReminderCommonText[2] += `${i + 1}、${
            companies.rank[i].name
          }（已筹金额：¥${companies.rank[i].order_price}）`;
        }
      }
      for (let i = 0; i < classes.rank.length; i++) {
        if (classes.rank[i].name && i < 3) {
          this.ReminderSchoolText[1] += `${i + 1}、${
            classes.rank[i].name
          }（已筹金额：¥${classes.rank[i].order_price}）`;
        }
      }
      for (let i = 0; i < terms.rank.length; i++) {
        if (terms.rank[i].name && i < 3) {
          this.ReminderSchoolText[2] += `${i + 1}、${
            terms.rank[i].name
          }（已筹金额：¥${terms.rank[i].order_price}）`;
        }
      }
      this.formLoading = false;
    },
    // 退款
    refundRow(row) {
      this.currentOrderId = row.orderid;
      this.openReasonDialog = true;
    },
  },
  computed: {
    tenantVersion() {
      return this.$store.getters.tenant.version;
    },
  },
};
</script>

<style lang="scss" scoped></style>
