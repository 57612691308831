import api from '@/base/utils/request';
import download from "@/base/utils/download";
import filesUpload from "@/base/utils/upload4";


// 捐款订单列表数据

export const OrderList = data => {
  return api({
    url: '/admin/donation/order/index',
    method: 'post',
    data
  });
};

// 获取所属架构/职务列表
export const positionLevel = data => {
  return api({
    url: "/admin/cyc/position/positionLevel",
    method: "post",
    data
  });
};

// 导出
export const OderExport = data => {
  return download({
    url: '/admin/donation/order/export',
    method: 'post',
    data,
    download: true
  });
};

// 上传发票
export const uploadInvoice = (data, extra) => {
  return filesUpload({
    url: '/admin/donation/order/uploadInvoice',
    data,
    extra,
    typeArr: ["file"]
  });
}

// 查看发票
export const checkInvoice = data => {
  return api({
    url: "/admin/donation/order/invoice",
    method: "post",
    data
  });
};

// 退款订单
export const orderRefund = data => {
  return api({
    url: "/admin/donation/order/refund",
    method: "post",
    data
  });
};