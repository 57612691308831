<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :onExport="onExport">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium"
      class="filter-from" @submit.native.prevent>
      <div class="filter-item" v-if="form.tab === 'personal'">
        <el-form-item label="用户名称：" prop="name">
          <el-input v-model="form.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="会内职务：" prop="positionIds">
          <el-cascader v-model="form.positionIds" :options="parentPositionList" :props="props" :placeholder="tenantVersion === 'school' ? '搜索班级':'请选择'"></el-cascader>
        </el-form-item>
      </div>
      <div class="filter-item" v-if="form.tab === 'company'">
        <el-form-item label="企业名称：" prop="name">
          <el-input v-model="form.name" placeholder="请输入"></el-input>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="订单号：" prop="orderid">
          <el-input v-model="form.orderid" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="订单状态：" prop="status">
          <el-select v-model="form.status" placeholder="请选择">
            <el-option v-for="item in TypeArr" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商户号：" prop="merchant">
          <el-input v-model="form.merchant" placeholder="请输入"></el-input>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker label="捐款时间：" start-prop="start_time" :start-time.sync="form.start_time"
          end-prop="end_time" :end-time.sync="form.end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { OderExport, positionLevel } from '../../api/order'

export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
    ids: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      rules: {},
      TypeArr: [
        { id: -1, name: "全部" },
        { id: 0, name: "已支付" },
        { id: 1, name: "已取消" },
        { id: 2, name: "待结束" },
        { id: 3, name: "已退款" },
      ],
      parentPositionList: [],
      // 联级选择配置
      props: {
        value: 'id',
        label: 'name',
        expandTrigger: 'hover',
        children: 'position'
      },
    };
  },
  methods: {
    getParentList() {
      positionLevel({ is_list_tree: 0, is_show_all: 1 })
      .then((res) => {
        this.parentPositionList = res.data
      })
      .catch(err => console.log(err))
    },
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    // 导出列表
    onExport() {
      const postData = { ...this.form, export_ids: this.ids, page: this.page };
      OderExport(postData).catch((err) => {});
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  created() {
    this.getParentList();
  },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
    position_id: {
      get() {
        return this.form.position_id == -1 ? "" : this.form.position_id;
      },
      set(val) {
        this.form.position_id = val
      }
    },
    tenantVersion() {
      return this.$store.getters.tenant.version;
    },
  },
  watch: {
    positionIds(val) {
      this.position_id = val[val.length -1] ? val[val.length - 1] : -1
    }
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>